.acpTable {
}

.acpTable thead > tr > th {
    font-weight: normal;
    line-height: 20px;
    padding: 3px 5px 5px 5px;
    border-bottom: 3px solid #fff;
    vertical-align: middle;

    background: #e7f3f9;
    color: #0a1f29;

    position: sticky;
    top: 0;

    z-index: 1;
}

.acpTable tbody > tr > td {
    background-color: #f5f5f5;
    border-top: 2px solid #fff;
    color: #666;
    font-size: 0.9em;
    padding: 5px;
}

.acpTableWithBulkActions thead > tr > th {
    top: 40px;
}
