.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 5px;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
}

.filterHeaderSelectedValue {
    border: 1px solid lightgrey;
    border-radius: 3px;
    background-color: #f5f5f5;
}

.selectedValue {
    text-overflow: ellipsis;
    overflow: hidden;
}

.filterTitleLink {
    color: #337ab7;
}
