.configurationModal {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
}

.modalBody {
    position: relative;
    padding: 15px;
    overflow-y: scroll;
    display: flex;
    max-height: 70vh;
    flex-direction: column;
}

.modalVisible {
    display: block;
    background: rgba(0, 0, 0, 0.7);
}

.configurationValues {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;

    div:not(:last-child) {
        padding-right: 20px;
    }

    ul:not(:last-child) {
        padding-right: 20px;
    }
}

.selectableItem {
    padding: 3px 10px;
    clear: both;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: #262626;
        background-color: #f5f5f5;
    }
}

.nestedList {
    padding-left: 0;
    list-style: none;
    min-width: 20%;
    border-top: 1px solid lightgrey;
    padding-top: 5px;
    margin-bottom: 5px;
}

.title {
    color: grey;
}

.faBlank {
    visibility: hidden;
}

.faDisabled {
    opacity: 0.1;
}
