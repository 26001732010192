.wrapper {
    padding: 8px 0;
    cursor: pointer;
    color: #39c;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectedProject {
    display: flex;
    flex-direction: column;
}

.moreIcon {
    margin: 0 10px;
}

.subheader {
    font-size: 65%;
    font-weight: 700;
}

.header {
    font-weight: 700;
}

.bodyOverride {
    padding: 0;
}

.defaultCursor {
    cursor: default;
}

.item {
    list-style: none;
    cursor: pointer;
}
.selectWrapper {
    padding: 0 0 5px;
    margin: 5px 0 0 5px;
}
.client-account-list__option {
    outline: 1px solid pink;
}

.clientSelected {
    font-weight: bold;
}

.childAccount {
    padding-left: 16px;
    display: block;
}
