.bulkActions {
    margin-top: 4px;
    padding: 5px;
    background: #d6ebf5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-flow: wrap;
}

.bulkActionsDescription {
    margin: 0;
    padding-top: 6px;
    padding-right: 10px;
}

.stickyBulkActions {
    position: sticky;
    top: 0;
    z-index: 1;
    left: 0;
    border-bottom: 2px white solid;
}
