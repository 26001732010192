.dropdownButton {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #39c;
}

.dropdownButton:hover {
    text-decoration: none;
    outline: none;
    color: #246b8f;
}
