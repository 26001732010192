.corridorTabs {
    width: 40px;
    border-left: 2px solid #eee;
    background: white;
    z-index: 1012;
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
}

.tabIcon {
    position: relative;
    height: 60px;
    color: #3399cc;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #f6f6f6;
        text-decoration: none;
    }
}

.corridorTabs i {
    font-size: 1.33333em;
}

.corridorTabsActive {
    background: #eee;
    color: #333;
}

.corridorTabs [aria-label]:before,
.corridorTabs [aria-label]:after {
    position: absolute;
    right: -1000em;
    transition: opacity 0.4s;
    opacity: 0;
}

.corridorTabs [aria-label]:before {
    text-align: center;
    content: attr(aria-label);
    width: 100px;
    padding: 4px;
    background: black;
    color: white;
    font-size: 11px;
    letter-spacing: 1pt;
    border-radius: 4px;
}

.corridorTabs [aria-label]:hover:before {
    right: 54px;
    opacity: 0.9;
}

.corridorTabs [aria-label]:after {
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid black;
}

.corridorTabs [aria-label]:hover:after {
    right: 48px;
    opacity: 0.9;
}
