.wrapper {
    position: fixed;
    width: 80%;
    max-width: 800px;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1011;

    display: flex;
}

.corridorContent {
    overflow-y: auto;
    flex: 1;
}

.corridorContentInner {
    padding: 12px 16px;
    /*height: 100%;*/
    /* need to think on solution to make 100% - header height */
}
