.removeTopPadding {
    margin-top: 0;
}

.centerPagination {
    text-align: center;
}

.paginationButtonSize {
    width: 20%;
    min-width: 200px;
}
