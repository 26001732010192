@import './node_modules/@acp/lib-ui-theme/light-theme/light-theme-variables';

.tooltipContainer {
    background-color: $gray-800;
    color: #fff;
    border-radius: 4px;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
    margin: 0.1rem 0.5rem;
    padding: 4px 8px;
    transition: opacity 0.4s;
    z-index: 2147483647;
    font-size: 12px;
    max-width: 600px;
}

.tooltipArrow {
    height: 1rem;
    position: absolute;
    width: 1rem;
}

.tooltipArrow::before {
    background-color: $gray-800;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;
}

.tooltipArrow::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
}

.tooltipArrow[data-placement*='bottom'] {
    height: 1rem;
    left: 0;
    margin-top: -0.4rem;
    top: 0;
    width: 1rem;
}

.tooltipArrow[data-placement*='bottom']::before {
    border-color: transparent transparent silver transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
    position: absolute;
    top: -1px;
}

.tooltipArrow[data-placement*='bottom']::after {
    border-color: transparent transparent $gray-800 transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltipArrow[data-placement*='top'] {
    bottom: 0;
    height: 1rem;
    left: 0;
    margin-bottom: -1rem;
    width: 1rem;
}

.tooltipArrow[data-placement*='top']::before {
    border-color: silver transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
    position: absolute;
    top: 1px;
}

.tooltipArrow[data-placement*='top']::after {
    border-color: $gray-800 transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltipArrow[data-placement*='right'] {
    height: 1rem;
    left: 0;
    margin-left: -0.7rem;
    width: 1rem;
}

.tooltipArrow[data-placement*='right']::before {
    border-color: transparent silver transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltipArrow[data-placement*='right']::after {
    border-color: transparent $gray-800 transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
    left: 5px;
    top: 0;
}

.tooltipArrow[data-placement*='left'] {
    height: 1rem;
    margin-right: -0.8rem;
    right: 0;
    width: 1rem;
}

.tooltipArrow[data-placement*='left']::before {
    border-color: transparent transparent transparent silver;
    border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltipArrow[data-placement*='left']::after {
    border-color: transparent transparent transparent $gray-800;
    border-width: 0.5rem 0 0.5rem 0.4em;
    left: 3px;
    top: 0;
}
