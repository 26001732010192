.nestedMenu {
    max-height: 400px;
    overflow-y: auto;
}

.menuItem {
    cursor: pointer;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}

.menuItem:hover {
    color: #262626;
    background-color: #f5f5f5;
}
