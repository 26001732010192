.headerIcon {
    opacity: 0.7;
}

.headerValue {
    padding-right: 4px;
}

.supertagIcon {
    height: 15px;
    margin-left: 3px;
}
