.link {
    padding: 0 4px;
}

.link:focus,
.link:active {
    outline: 0;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}
