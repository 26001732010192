.notesCell {
    width: 300px;
}

.notesCell > .editIcon {
    width: 12px;
    visibility: hidden;
}

.notesCell:hover > .editIcon {
    width: 12px;
    visibility: unset;
}

.notesCell:hover > .editIcon:hover {
    cursor: pointer;
}

.link {
    cursor: pointer;
    color: #337ab7;
}

.link:hover {
    color: #23527c;
}

.content {
    max-width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.notesTooltip .link {
    color: #3287c3;
}
