.mentions__control {
    margin-top: 10px;
    margin-bottom: 20px;
}
.mentions__input {
    line-height: 1.5em;
    min-height: 6em;
}
.mentions__highlighter {
    line-height: 1.5em;
    min-height: 6em;
}
.mentions__suggestions__list {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    max-height: 200px;
    overflow: auto;
}
.mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &:hover {
        background-color: #1da1f2;
    }
}

.mentions__suggestions__item — focused {
    background-color: #1da1f2;
}

.mentions__mention {
    color: #1da1f2; //twitter blue
    z-index: 1;
    position: relative;
}

.trendingTagName {
    color: #1da1f2; //twitter blue
}

.trendingTagCount {
    margin-left: 0.25em;
}

.trendingTagList {
    list-style-type: none;
    padding-inline-start: 0px;
}
