.wrap {
    overflow-x: auto;
    width: 100%;
}

.logo {
    height: 15px;
}

.actionButtonSpacing {
    margin-right: 8px;
}
