.facetGroup {
    margin: 5px 0 0 15px;
    padding: 0 0 5px;
}

.item {
    position: relative;
    margin: 4px 0 0;
    color: #337ab7;
    cursor: pointer;
    list-style: none;
}

.title {
    display: block;
    padding-right: 42px;
    overflow: hidden;
    font-style: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.count {
    position: absolute;
    top: 0;
    right: 4px;
}

.checkbox {
    position: absolute;
    top: 0;
    left: 20px;
    display: block;
    padding-right: 42px;
    overflow: hidden;
    font-style: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.selectFeature {
    position: relative;
    margin: 4px 0 0 15px;
    font-size: 10px;
}

.selectFeatureButton {
    color: #337ab7;
    cursor: pointer;
}
