.wrapper {
    word-break: break-all; /* IE */
    word-break: break-word; /* other */
    padding: 0;

    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.copyButton {
    padding-left: 8px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.copyButton:active {
    opacity: 0.8;
}

.checkmark {
    color: lawngreen;
}
