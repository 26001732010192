.checkbox {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: #666;
    opacity: 0.1;
}

.checkboxSelected {
    opacity: 1;
}
