.errorBoundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
}

.errorPanel {
    width: 600px;
    border: none;
    padding: 16px;
    border-radius: 5px;
}

.title {
    font-size: 24px;
    font-weight: 800;
}

.subtitle {
    font-size: 18px;
}

.detailsButton {
    color: #1a1a1a;
    margin-top: 8px;
}

.detailsButton:active,
.detailsButton:active:focus,
.detailsButton:focus {
    outline: none;
}

.details {
    background-color: #dddddd;
    color: #1a1a1a;
    margin: 8px 0 0;
    padding: 8px;
    border-radius: 4px;
}

.reset {
    margin-left: 12px;
}

.time {
    padding-top: 5px;
}
