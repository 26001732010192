.tileCatalog {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.logo {
    height: 50px;
    align-self: center;
}

.serviceTile {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    width: 280px;
    margin: 0;
    padding: 16px;
}

.serviceTitle {
    text-align: center;
}

.description {
    flex-grow: 1;
    margin-bottom: 8px;
}

.actions {
    display: flex;
    flex-direction: column;
}

.actionButtonSpacing {
    margin-top: 8px;
}
