$togglerButtonSize: 32px;
$asideWidth: 240px;
$buttonMarginSum: 8px;
$sectionPaddings: 8px;

.layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

/* TOP */
.top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.context {
    flex-basis: $asideWidth;
    flex-shrink: 0;
    align-self: stretch;
    padding: $sectionPaddings;
    border-right: 1px solid #ddd;
}

.header {
    width: 100%;
    padding: $sectionPaddings;
}

/* MAIN */
.main {
    display: flex;
    flex-grow: 1;
    min-height: 40px;
    overflow: hidden;
}

.aside {
    flex-basis: $asideWidth;
    flex-shrink: 0;
    position: relative;
    padding: $sectionPaddings;
    border-right: 1px solid #ddd;
    margin-left: 0;
    transition: margin-left 0.2s;
    transform: translate3d(0, 0, 0);

    &.closed {
        margin-left: -($asideWidth);
    }
}

.asideToggler {
    position: relative;
    width: $togglerButtonSize;
    height: $togglerButtonSize;
    margin-right: 24px;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: -13px;
        top: 0;
        bottom: 0;
        border-left: 1px solid #ddd;
        z-index: 0; // Fix for firefox to be behind overlay when open modal
    }

    &.alone:after {
        display: none;
    }
}

.contentWrap {
    display: flex;
    flex-direction: column;
    flex-basis: inherit;
    width: 100%;
    min-width: 0; // fixes scroll issues
}

.toolbar {
    display: flex;
    align-items: center;
    padding: $sectionPaddings;
    border-bottom: 1px solid #ddd;
}

.content {
    flex-grow: 1;
    padding: 8px;
}

.title {
    margin: 0;
    padding: 4px 0;
    font-size: 18px;
    font-weight: normal;
}
