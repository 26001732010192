.modalVisible {
    display: block;
    background: rgba(0, 0, 0, 0.7);
}

button.closeButton {
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 8px;
    padding: 8px;
}

.modalMedium {
    max-width: 650px !important;
}
