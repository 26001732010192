.paginationAlignLeft {
    display: flex;
    align-items: center;
}

.paginationAlignRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.paginationBtnNext {
    margin-left: 4px;
}

.paginationSummary {
    margin-left: 8px;
    margin-right: 4px;
}

.disabled {
    cursor: not-allowed;
}
