.catalogWithFilters {
    display: flex;
}

.filtersPanel {
    flex-basis: 25vw;
    border-right: 2px solid #eee;
    padding: 0 8px;
}

.catalogPanel {
    padding: 0;
}

.catalogPanelWithFilter {
    flex-basis: 75vw;
}

.catalogPanelWithoutFilter {
    flex-basis: 100vw;
}

.toolbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
